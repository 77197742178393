/*global gtag*/
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Arrow } from "@components/UI"
import { ClientList } from "@components/ClientList"
import { AltAboutJk } from "@components/AboutJk"
import { SalesForm } from "@components/Form"
import { Lottie } from "@components/Lottie"
import { Button } from "@components/Button"
import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { splitText } from "@utils"
import { PillButton } from "@components/Button"
import megaphoneLottie from "@lottie/megaphone_c.json"
import blockheartsLottie from "@lottie/blockhearts_c.json"
import flowLottie from "@lottie/flow_b.json"
import HeroShapeOne from "@svg/employer-branding/hero-shape-01.svg"
import HeroShapeTwo from "@svg/employer-branding/hero-shape-02.svg"

const EmployerBrandPage = ({ data }) => {
  const { ricohDesktop, ricohMobile, marcosDesktop, marcosMobile, aboutJkImage, heroImage } = data
  const { themeColor } = usePageSetup({ themeColor: "bg-teal-light", displayGeneralForm: false })

  const contactSubmission = () => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) {
      return
    }
    gtag("event", "contact_us_form_submission", { event_category: "contact_us", event_label: "eb_contact_submit" })
  }

  const getTheGuide = () => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) {
      return
    }
    gtag("event", "get_the_guide", { event_category: "click", event_label: "get_the_guide" })
  }

  return (
    <Layout>
      <Seo title="Employer Branding" description="" />
      <div className="overflow-hidden">
        <div className={themeColor} style={{ height: "94px" }}></div>
        <div className={themeColor}>
          <div className="container relative">
            <div className="absolute inset-x-0 bottom-0">
              <div className="aspect-w-326 aspect-h-93 sm:aspect-w-667 lg:aspect-w-1436 sm:aspect-h-78 extend before:bg-white-dark after:bg-white-dark"></div>
            </div>

            <div className="relative sg-hero-grid">
              <div className="mb-8 sg-title pt-14">
                <div>
                  <div data-aos="fade">
                    <PillButton staticButton={true}>Employer Branding</PillButton>
                  </div>
                  <h1 className="mt-12 text-clamp-30-35" data-aos="fade-up">
                    Rev up your recruiting, retention, and employee engagement with a more powerful employer brand
                    story.
                  </h1>
                </div>
              </div>
              <div className="pb-15 sm:pb-40 sg-message" data-aos="fade-up">
                <p className="text-gray-darkest text-clamp-18-22">
                  We’re experts in crafting and implementing authentic talent stories, ones that make meaningful
                  connections with the people who power your success.
                </p>
                <div>
                  <Button className="mt-8 text-gray-darkest" link="#eb-form">
                    Get in touch with an expert now
                  </Button>
                </div>
              </div>
              <div className="flex sg-image">
                <div className="grid self-end w-full h-full grid-cols-8 sm:grid-cols-6">
                  <div className="relative col-span-7 sm:col-span-6 lg:col-span-5 lg:col-start-2">
                    <div className="inset-0 pb-6 sm:flex sm:pl-5 sm:absolute lg:pl-0 lg:pr-5 sm:pb-0">
                      <div className="relative self-end w-full h-full lg:w-auto lg:h-auto" data-aos="fade-left">
                        <div className="absolute inset-y-0 right-0 flex">
                          <div className="transform scale-110 translate-x-8 sm:translate-x-2 lg:translate-x-8">
                            <HeroShapeOne />
                          </div>
                        </div>
                        <GatsbyImage
                          objectFit="contain"
                          className="w-full h-full"
                          image={getImage(heroImage)}
                          alt="Social good storytelling"
                        />
                        <div className="absolute inset-y-0 right-0 flex">
                          <div className="transform scale-110 translate-x-8 sm:translate-x-2 lg:translate-x-8">
                            <HeroShapeTwo />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main>
          <section className="pb-clamp-28-33">
            <div className="container">
              <div className="flex lg:justify-center">
                <div>
                  <div>
                    <PillButton staticButton={true} className="bg-teal-light">
                      Our services
                    </PillButton>
                  </div>
                  <div className="grid lg:grid-cols-10 gap-x-4 gap-y-8 md:gap-y-16 mt-14">
                    <div data-aos="fade-up" className="items-center lg:col-span-3 sm:flex lg:flex-col">
                      <div className="flex w-full mr-auto" style={{ height: "112px", maxWidth: "175px" }}>
                        <Lottie animation={blockheartsLottie} width="94px" />
                      </div>
                      <div>
                        <h3 className="mt-10 text-clamp-18-22 sm:mt-0 lg:mt-8">Employer brand and EVP development</h3>
                        <p className="text-16 mt-clamp-6-8">
                          Own your talent story by crafting an informed employee value proposition and authentic
                          employer brand that will connect with the people you need to retain and attract.
                        </p>
                      </div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="200" className="flex flex-col lg:col-span-4">
                      <div className="items-center lg:w-3/4 lg:mx-auto sm:flex lg:flex-col">
                        <div className="flex w-full mr-auto" style={{ height: "112px", maxWidth: "175px" }}>
                          <Lottie animation={flowLottie} width="125px" />
                        </div>
                        <div>
                          <h3 className="mt-10 text-clamp-18-22 sm:mt-0 lg:mt-8">
                            Employer brand strategy and implementation
                          </h3>
                          <p className="text-16 mt-clamp-6-8">
                            Activate your EVP and employer brand across every talent touchpoint&mdash;through your
                            career site, employee engagement campaigns, leader toolkits, candidate comms, and more.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      data-aos="fade-up"
                      data-aos-delay="400"
                      className="items-center lg:col-span-3 gap-x-6 sm:flex lg:flex-col"
                    >
                      <div className="flex mr-auto lg:w-full" style={{ height: "112px", maxWidth: "175px" }}>
                        <Lottie animation={megaphoneLottie} width="147px" />
                      </div>
                      <div>
                        <h3 className="mt-10 text-clamp-18-22 sm:mt-0 lg:mt-8">
                          Recruitment<br></br> marketing
                        </h3>
                        <p className="text-16 mt-clamp-6-8">
                          Fuel your talent acquisition funnel with a digital recruitment campaign that puts your story
                          front and center with your target candidates.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="bg-blue-lighter py-clamp-18-34">
            <div className="container">
              <div className="flex lg:justify-center">
                <div className="grid lg:gap-16 lg:grid-cols-2">
                  <div>
                    <p className="text-20">Now’s the time to showcase your value.</p>
                    <p className="font-bold text-clamp-25-45">
                      A recent Harris Poll found that 52% of U.S. workers are thinking about a new job, and 44% are
                      actively planning to make the change.
                    </p>
                  </div>
                  <div className="mt-7 lg:mt-0">
                    <p className="text-clamp-18-28">
                      In this dynamic talent landscape, showcasing your value as an employer in a clear and compelling
                      way is more important than ever. JK will help you craft and tell your most powerful and engaging
                      talent story, backed by over 20 years of employee engagement experience and a proven,
                      insight-driven employer brand development process.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

          <section className="bg-white-dark py-clamp-24-28">
            <div className="container">
              <div className="flex lg:justify-center">
                <div className="lg:w-10/12">
                  <div className="grid-cols-10 sm:grid">
                    <div className="col-span-8">
                      <h2
                        className="text-clamp-35-45"
                        data-aos="stagger"
                        dangerouslySetInnerHTML={{
                          __html: splitText({
                            "text-teal": "Looking for information and inspiration? ",
                            "text-gray-darkest": "We have you covered.",
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className="grid mt-16 md:grid-cols-2 lg:grid-cols-10 gap-x-4 gap-y-8 md:gap-y-16">
                    <div data-aos="fade-up" className="flex flex-col lg:col-span-3">
                      <p>Creating internal buy-in for your employer brand.</p>
                      <div className="mt-auto">
                        <Button
                          func={getTheGuide}
                          link="/documents/jk-employer-branding-buy-in-guide.pdf"
                          external={true}
                          className="sm:hover:text-gray-darkest"
                        >
                          Get the guide
                        </Button>
                      </div>
                    </div>
                    <div className="flex flex-col lg:col-span-4">
                      <div data-aos="fade-up" className="flex flex-col lg:w-3/4 lg:mx-auto">
                        <p className="pb-6">
                          Why transparency is essential to B2B employer branding—and 5 ways to achieve it.
                        </p>
                        <div className="mt-auto">
                          <Button
                            link="/ideas-and-insights/why-transparency-is-essential-to-b2b-employer-branding-and-5-ways-to-achieve-it/"
                            className="sm:hover:text-gray-darkest"
                          >
                            Read the blog
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div data-aos="fade-up" className="flex flex-col lg:col-span-3">
                      <p className="pb-6">5 ways to make internal communications more engaging.</p>
                      <div className="mt-auto">
                        <Button
                          link="/ideas-and-insights/5-ways-to-make-internal-communications-more-engaging/"
                          className="sm:hover:text-gray-darkest"
                        >
                          Read the blog
                        </Button>
                      </div>
                    </div>
                    <div data-aos="fade-up" className="flex flex-col lg:col-span-3">
                      <p className="pb-6">Corporate branding vs. employer branding: One size DOES NOT fit all.</p>
                      <div className="mt-auto">
                        <Button
                          link="/ideas-and-insights/corporate-branding-vs-employer-branding-one-size-does-not-fit-all"
                          className="sm:hover:text-gray-darkest"
                        >
                          Read the blog
                        </Button>
                      </div>
                    </div>
                    <div data-aos="fade-up" className="lg:col-span-4">
                      <div className="flex flex-col lg:w-3/4 lg:mx-auto">
                        <p className="pb-6">Employer branding: Making the case for keeping it real.</p>
                        <div className="mt-auto">
                          <Button
                            link="/ideas-and-insights/employer-branding-making-the-case-for-keeping-it-real/"
                            className="sm:hover:text-gray-darkest"
                          >
                            Read the blog
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div data-aos="fade-up" className="flex flex-col lg:col-span-3">
                      <p className="pb-6">Employer branding: Why having a great EVP isn’t enough.</p>
                      <div className="mt-auto">
                        <Button
                          link="/ideas-and-insights/why-having-a-great-evp-isnt-enough/"
                          className="sm:hover:text-gray-darkest"
                        >
                          Read the blog
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          <section className="bg-gray-darkest py-clamp-18-25">
            <div className="container">
              <div className="flex lg:justify-center">
                <div className="w-full">
                  <div data-aos="fade">
                    <PillButton staticButton={true}>Our clients & testimonials</PillButton>
                  </div>
                  <h2
                    className="mt-12 text-clamp-35-45"
                    data-aos="stagger"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-teal": "Stories we’ve told ",
                        "text-white-dark": "along the way.",
                      }),
                    }}
                  />
                  <ClientList
                    className="text-white fill-current gap-x-6 lg:gap-x-16 mt-clamp-9-8"
                    clients={[
                      "j-and-j",
                      "tiffany",
                      "adp",
                      "heineken",
                      "cheesecake",
                      "marcos",
                      "prudential",
                      "elementis",
                      "priceline",
                      "republic",
                    ]}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="relative overflow-hidden pb-clamp-20-42 bg-gray-darkest">
            <div className="absolute inset-0 flex">
              <div className="w-full mt-auto h-1/2 bg-white-dark"></div>
            </div>
            <div className="container relative flex">
              <div className="w-full sm:w-10/12 lg:mx-auto">
                <Carousel>
                  <CursorMarker>
                    <CursorMarker.Default className="bg-white after:text-purple" />
                  </CursorMarker>
                  <Carousel.Swiper>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay={200 * 0}>
                        <Carousel.Quote client="adp"></Carousel.Quote>
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay={200 * 1}>
                        <Carousel.Quote client="ricoh"></Carousel.Quote>
                      </div>
                    </Carousel.Slide>
                  </Carousel.Swiper>
                </Carousel>
              </div>
            </div>
            <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          </section>
          <section className="bg-teal-light pt-clamp-20-44 pb-clamp-23-37">
            <div className="container">
              <div className="flex xl:justify-center">
                <div className="w-9/12 xl:w-10/12">
                  <h2
                    className="text-clamp-35-62"
                    data-aos="stagger"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-gray-darkest": "See our storytelling in action.",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="mt-clamp-15-28">
                <div className="hidden md:flex lg:justify-center">
                  <div className="grid grid-cols-2 xl:w-10/12 gap-x-4">
                    <div data-aos="fade-up" className="flex flex-col">
                      <div className="grid grid-cols-10">
                        <div className="flex flex-col col-span-9">
                          <h3 className="font-semi text-22">Ricoh</h3>
                          <GatsbyImage
                            className="mt-10"
                            objectFit="contain"
                            image={getImage(ricohDesktop)}
                            alt="Ricoh"
                          />
                        </div>
                      </div>
                      <div className="grid h-full grid-cols-10">
                        <div className="flex flex-col col-span-8">
                          <h4 className="mt-14 text-22 lg:pr-20">
                            Connecting with employees during a time of transformation.
                          </h4>
                          <p className="mt-6 text-18 pb-9">
                            Ricoh has made a tremendous pivot as a business, shifting their focus from office printing
                            solutions to enabling cutting-edge digital workplaces. Being successful in a time of such
                            change requires keeping employees motivated and focused on what lies ahead while attracting
                            the type of talent that will move their business forward.
                          </p>
                          <div className="mt-auto">
                            <Button link="/work/ricoh" className="sm:hover:text-gray-darkest">
                              Read the full case study
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="200" className="flex flex-col">
                      <div data-aos="fade-up" className="grid grid-cols-10">
                        <div className="flex flex-col col-start-2 col-end-11">
                          <h3 className="font-semi text-22">Marco’s</h3>
                          <GatsbyImage
                            className="mt-10"
                            objectFit="contain"
                            image={getImage(marcosDesktop)}
                            alt="Ricoh"
                          />
                        </div>
                      </div>
                      <div className="grid h-full grid-cols-10">
                        <div className="flex flex-col col-start-2 col-end-10">
                          <h4 className="mt-14 text-22 lg:pr-20">
                            Rallying talent to build a stronger, more stable business.
                          </h4>
                          <p className="mt-6 text-18 pb-9">
                            JK worked closely with Marco’s stakeholders to build the foundations of a compelling and
                            original employer brand, one rooted in their brand DNA of working hard to deliver
                            amazing—or, as they say at Marco’s, “primo”—guest experiences.
                          </p>
                          <div className="mt-auto">
                            <Button link="/work/marcos" className="sm:hover:text-gray-darkest">
                              Read the full case study
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:hidden">
                  <Carousel>
                    <CursorMarker>
                      <CursorMarker.Default className="bg-white after:text-purple" />
                    </CursorMarker>
                    <Carousel.Swiper>
                      <Carousel.Slide>
                        <div className="grid w-full grid-cols-12">
                          <div className="col-span-11">
                            <div data-aos="fade-up" className="flex flex-col">
                              <div data-aos="fade-up">
                                <div className="flex flex-col">
                                  <h3 className="font-semi text-22">Ricoh</h3>
                                  <GatsbyImage
                                    className="mt-10"
                                    objectFit="contain"
                                    image={getImage(ricohMobile)}
                                    alt="Marcos"
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="flex flex-col">
                                  <h4 className="mt-14 text-22 lg:pr-20">
                                    Connecting with employees during a time of transformation.
                                  </h4>
                                  <p className="mt-6 text-18 pb-9">
                                    Ricoh has made a tremendous pivot as a business, shifting their focus from office
                                    printing solutions to enabling cutting-edge digital workplaces. Being successful in
                                    a time of such change requires keeping employees motivated and focused on what lies
                                    ahead while attracting the type of talent that will move their business forward.
                                  </p>
                                  <div className="mt-auto">
                                    <Button link="/work/ricoh" className="sm:hover:text-gray-darkest">
                                      Read the full case study
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Carousel.Slide>
                      <Carousel.Slide>
                        <div className="grid w-full grid-cols-12">
                          <div className="col-span-11">
                            <div data-aos="fade-up" data-aos-delay="200" className="flex flex-col">
                              <div data-aos="fade-up">
                                <div className="flex flex-col">
                                  <h3 className="font-semi text-22">Marco’s</h3>
                                  <GatsbyImage
                                    className="mt-10"
                                    objectFit="contain"
                                    image={getImage(marcosMobile)}
                                    alt="Marcos"
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="flex flex-col">
                                  <h4 className="mt-14 text-22 lg:pr-20">
                                    Rallying talent to build a stronger, more stable business.
                                  </h4>
                                  <p className="mt-6 text-18 pb-9">
                                    JK worked closely with Marco’s stakeholders to build the foundations of a compelling
                                    and original employer brand, one rooted in their brand DNA of working hard to
                                    deliver amazing—or, as they say at Marco’s, “primo”—guest experiences.
                                  </p>
                                  <div className="mt-auto">
                                    <Button link="/work/marcos" className="sm:hover:text-gray-darkest">
                                      Read the full case study
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Carousel.Slide>
                    </Carousel.Swiper>
                  </Carousel>
                </div>
              </div>
            </div>
          </section>
          <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          <section className="pt-clamp-44-62 pb-clamp-37-60">
            <AltAboutJk>
              <AltAboutJk.Content>
                JK helps B2B companies of all types and sizes discover and build the foundation of their story, give it
                an authentic voice and distinctive look, and tell it in unique, compelling, and strategic ways that
                connect with the people who matter most.
              </AltAboutJk.Content>
              <AltAboutJk.ImageV2 image={aboutJkImage} shape="one" />
            </AltAboutJk>
          </section>
          <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          <div id="eb-form" style={{ scrollMarginTop: "78px" }}></div>
          <section className="bg-teal-light pt-clamp-34-51 pb-clamp-27-40">
            <div className="container flex lg:justify-center">
              <div className="xl:w-10/12">
                <div className="grid-cols-10 lg:grid">
                  <div className="col-span-7">
                    <h2
                      data-aos="stagger"
                      className="text-title"
                      dangerouslySetInnerHTML={{
                        __html: splitText({
                          "text-white": "Let’s build a ",
                          "text-gray-darkest": "stronger talent story, together.",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="grid-cols-10 lg:grid mt-clamp-7-11">
                  <div className="col-span-8 xl:col-span-7">
                    <p data-aos="fade-up">Please fill out the form below and we’ll be in touch soon.</p>
                  </div>
                </div>
              </div>
            </div>
            <section className="container mt-24">
              <div className="mx-auto xl:w-10/12">
                <SalesForm
                  id="632"
                  subject="JK Design - Employer Branding"
                  recipient="katiek@jkdesign.com"
                  fieldColor="bg-white-dark"
                  fn={contactSubmission}
                />
              </div>
            </section>
          </section>
        </main>
      </div>
    </Layout>
  )
}

export default EmployerBrandPage

export const EmployerBrandQuery = graphql`
  query employerBrandQuery {
    heroImage: file(relativePath: { eq: "employee-branding/hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bannerDesktop: file(relativePath: { eq: "employee-branding/banner-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    bannerMobile: file(relativePath: { eq: "employee-branding/banner-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricohDesktop: file(relativePath: { eq: "employee-branding/ricoh-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ricohMobile: file(relativePath: { eq: "employee-branding/ricoh-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcosDesktop: file(relativePath: { eq: "employee-branding/marcos-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    marcosMobile: file(relativePath: { eq: "employee-branding/marcos-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    aboutJkImage: file(relativePath: { eq: "global/about-jk.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
